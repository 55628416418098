import * as React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CoachCard from '../components/CoachCard';
import AvatarMenu from '../components/AvatarMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Layout from "../components/layout"
import CoachesData from '../content/coaches.json';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SEO from "../components/seo"
import { navigate } from "gatsby"

const CoachesPage = (props) => {
  const theme = useTheme();
  const data = props.data;
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [coach, setCoach] = React.useState(props.pageContext ? props.pageContext.coachData : null);

  return (
  <Layout>
    <SEO title={coach ? `Lessons \u00B7 ${coach.name}` : "Lessons"} image={coach ? data[coach.id+"_profile"].childImageSharp.resize : null}/>
    <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
      <Grid item style={{padding: '2rem'}} xs={12}>
        <Typography variant="h3" style={{color: '#243E83'}}>Make The Cut - Get Recruited</Typography>
        <Typography variant="h4"><i>Personalized training with our coaches to get you to the next level</i></Typography>
      </Grid>
      <Grid item xs={12} sm={8} style={{padding: '2rem'}}>
        <Typography variant="paragraph">
          Oftentimes the same reps and bi-weekly practice with the team just results in improvement at the same pace as everyone else.
          High Schools and Clubs will usually have to cater practices to work on team wide skills leaving little time for players to individually improve.
          Our coaches are incredibly passionate about helping athletes succeed and have the expertise to cater to each individual player's learning styles
          in order to guarantee significant improvement. You can also pair up with a friend and do semi-private lessons for a reduced rate and
          the option to recieve specialized pairs training. We offer a <u>satisfaction guarantee</u> for your first lesson, so feel free to give it a try;
          we're confident you'll learn a lot, improve, and have a lot of fun while playing volleyball.
        </Typography>
      </Grid>
      <Grid item style={{padding: '2rem'}} xs={12}>
        <Typography variant="h6" style={{color: '#243E83', borderBottom: '1px solid #F78D1F', marginBottom: '15px'}}>Click on one of the coache's portraits to learn more and schedule a lesson!</Typography>
        <AvatarMenu style={{justifyContent: 'center', flexWrap: 'wrap'}}>
          {Object.values(CoachesData).map((coachData) => { return (
            <Link to={`/lessons/${coachData.id}`} label={coachData.name} width={100}><GatsbyImage image={data[coachData.id+"_profile"].childImageSharp.gatsbyImageData} 
              quality={90} /></Link>)
            })
          }
        </AvatarMenu>
      </Grid>
      {Object.values(CoachesData).map((coachData) => { return (
        <Modal open={coach && coach.id == coachData.id} onClose={() => { navigate('/lessons/');}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CoachCard coachKey={coachData.id} dispatch={() => navigate('/lessons/')} shareUrl={props.location.href}
            image={{portrait: data[coachData.id+"_portrait"].childImageSharp.gatsbyImageData, action: data[coachData.id+'_action'].childImageSharp.gatsbyImageData}} />
        </Modal>)})}
    </Grid>
  </Layout>
)}

export default CoachesPage

export const query = graphql`
  query {
    edina_blazevic_portrait: file(relativePath: {eq: "edina_blazevic_action.jpg"}) {
      ...cardImage
    }
    edina_blazevic_action: file(relativePath: {eq: "edina_blazevic_action.jpg"}) {
      ...cardImage
    }
    edina_blazevic_profile: file(relativePath: {eq: "edina_blazevic_profile.jpg"}) {
      ...cardImage
    }
    enzo_lulushi_portrait: file(relativePath: {eq: "enzo_lulushi_portrait.jpg"}) {
        ...cardImage
    }
    enzo_lulushi_action: file(relativePath: {eq: "enzo_lulushi_action.jpg"}) {
      ...cardImage
    }
    enzo_lulushi_profile: file(relativePath: {eq: "enzo_lulushi_profile.jpg"}) {
      ...cardImage
    }
    kristen_falcinelli_portrait: file(relativePath: {eq: "kristen_falcinelli_portrait.jpg"}) {
      ...cardImage
    }
    kristen_falcinelli_action: file(relativePath: {eq: "kristen_falcinelli_action.jpg"}) {
      ...cardImage
    }
    kelly_hughes_portrait: file(relativePath: {eq: "kelly_hughes_portrait.jpg"}) {
      ...cardImage
    }
    kelly_hughes_action: file(relativePath: {eq: "kelly_hughes_portrait.jpg"}) {
      ...cardImage
    }
    kristen_falcinelli_profile: file(relativePath: {eq: "kristen_falcinelli_profile.jpg"}) {
      ...cardImage
    },
    kelly_hughes_profile: file(relativePath: {eq: "kelly_hughes_profile.jpg"}) {
      ...cardImage
    }
    jordan_mckinney_profile: file(relativePath: {eq: "jordan_mckinney_profile.jpg"}) {
      ...cardImage
    }
    jordan_mckinney_portrait: file(relativePath: {eq: "jordan_mckinney_action.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          transformOptions: {cropFocus: NORTHWEST}
        )
        resize {
          height
          width
          src
        }
      }
    }
    jordan_mckinney_action: file(relativePath: {eq: "jordan_mckinney_action.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          transformOptions: {cropFocus: NORTHWEST, fit: COVER, grayscale: false}
        )
        resize {
          height
          width
          src
        }
      }
    }
  }
`